<template>
  <div class="open_platform">
    <!-- 头部 + banner -->
    <div class="header">
      <div class="flex_between header_top content_box">
        <div class="open_platform_logo" @click="$router.replace({path: 'openPlatform'})">
          <img src="../../../static/img/openPlatform/logo.png">
        </div>
        <div class="flex_between header_nav">
          <div class="action_nav">首页</div>
          <!-- <div>接口文档</div> -->
          <div @click="$router.replace({path: 'applyAccess'})">申请接入</div>
          <!-- <div>管理后台</div> -->
        </div>
      </div>
    </div>
    <div class="open_platform_tab">
      <div class="content_box">
        <div class="tabs flex_between">
          <div class="tab flex_between box_shadow" :class="{'tab_action': tabIndex == 0}" @click="tabIndex = 0">
            <img v-if="tabIndex == 0" class="tab_img" src="../../../static/img/openPlatform/first_logo_action.png">
            <img v-else class="tab_img" src="../../../static/img/openPlatform/first_logo.png">
            <div class="tab_details">
              <div class="title">复诊开药服务</div>
              <div class="content">接入医生复诊开药服务，实现合规销售处方药</div>
            </div>
          </div>
          <div class="tab flex_between box_shadow" :class="{'tab_action': tabIndex == 1}" @click="tabIndex = 1">
            <img v-if="tabIndex == 1" class="tab_img" src="../../../static/img/openPlatform/second_logo_action.png">
            <img v-else class="tab_img" src="../../../static/img/openPlatform/second_logo.png">
            <div class="tab_details">
              <div class="title">在线问诊服务</div>
              <div class="content">接入医生在线咨询服务，实现会员服务升级提升竞争力</div>
            </div>
          </div>
        </div>
        <div class="tabs_content">
          <div class="tabs_content_top box_shadow">
            <div class="tabs_content_details">
              <div class="service_profile">
                <div class="title">服务简介</div>
                <div v-if="tabIndex == 0" class="content">
                  <div>根据国家相关法律法规，处方药在线销售，需要先由互联网医院医生进行问诊开方，然后患者凭方购买；</div>
                  <div>通过"莲藕健康 | 在线问诊开放平台"，可以快速且低成本改造原有的药品在线购买流程，使其具备合规销售处方药的能力，拓宽网上销售品类，搭上医药电商升级的快车；</div>
                  <div>“莲藕医生”专业服务，7*24小时，30秒极速响应，已服务超8000+家医药企业。</div>
                </div>
                <div v-else class="content">
                  <div>莲藕医生贯彻“健康中国2030”指导思想及分级诊疗制度指导意见，打造医生在线问诊服务能力。</div>
                  <div>通过“莲藕健康|开放平台”，接入莲藕医生在线问诊服务，可以实现为会员提供健康问题解答与治疗建议服务，实现会员服务能力升级，提高患者满意度，进一步提升转化。</div>
                  <div>在线问诊服务可应用到公众号，在线商城，私域群聊，会员服务等场景中。</div>
                </div>
              </div>
              <div class="for_enterprise">
                <div class="title">适用企业</div>
                <div class="content">
                  <div class="flex_start">
                    <div class="flex_center margin_right_82"><img src="../../../static/img/openPlatform/icon_tick.png"><span>医药电商平台</span></div>
                    <div class="flex_center"><img src="../../../static/img/openPlatform/icon_tick.png"><span>药店SAAS系统提供商</span></div>
                  </div>
                  <div class="flex_start">
                    <div class="flex_center margin_right_82"><img src="../../../static/img/openPlatform/icon_tick.png"><span>医药零售商城</span></div>
                    <div class="flex_center"><img src="../../../static/img/openPlatform/icon_tick.png"><span>药店ERP系统提供商</span></div>
                  </div>
                </div>
              </div>
              <div class="support_access_system">
                <div class="title">支持接入系统</div>
                <div class="flex_start content">
                  <div class="flex_column">
                    <img class="sas_img" src="../../../static/img/openPlatform/icon_h5.png">
                    <span>H5</span>
                  </div>
                  <div class="flex_column">
                    <img class="sas_img" src="../../../static/img/openPlatform/icon_applets.png">
                    <span>小程序</span>
                  </div>
                  <div class="flex_column">
                    <img class="sas_img" src="../../../static/img/openPlatform/icon_app.png">
                    <span>APP</span>
                  </div>
                  <div class="flex_column">
                    <img class="sas_img" src="../../../static/img/openPlatform/icon_pc.png">
                    <span>PC</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="sample_img">
              <img v-if="tabIndex == 1" src="../../../static/img/openPlatform/online_card.png">
              <img v-else src="../../../static/img/openPlatform/visit_card.png">
            </div>
          </div>
          <div v-if="tabIndex == 1" class="application_interaction_example">
            <div class="title">应用场景</div>
            <div class="aie_img box_shadow">
              <img src="../../../static/img/openPlatform/application_scenarios.png" alt="" srcset="">
            </div>
          </div>
          <div class="application_interaction_example">
            <div class="title">应用交互示例</div>
            <div v-if="tabIndex == 0" class="aie_tabs flex_center">
              <div class="aie_tab box_shadow" :class="{'aie_tab_action': aibTabIndex == 0}" @click="aibTabIndex = 0">先问诊后购药</div>
              <div class="aie_tab box_shadow" :class="{'aie_tab_action': aibTabIndex == 1}" @click="aibTabIndex = 1">先预定后问诊</div>
            </div>
            <div class="aie_img box_shadow">
              <img v-show="tabIndex == 1" src="../../../static/img/openPlatform/Application_Interaction_Example.png" alt="" srcset="">
              <img v-show="aibTabIndex == 0 && tabIndex == 0" src="../../../static/img/openPlatform/Application_Interaction_Example2.png" alt="" srcset="">
              <img v-show="aibTabIndex == 1 && tabIndex == 0" src="../../../static/img/openPlatform/Application_Interaction_Example3.png" alt="" srcset="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperative_enterprise" />
    <div class="our_advantages">
      <div class="content_box">
        <div class="title">我们的优势</div>
        <div class="content">
          <div class="our_advantages_top flex_between">
            <div class="flex_start">
              <div class="advantages flex_center flex_column">
                <div class="advantages_top">
                  <span class="num">7</span>
                  <span class="unit">秒</span>
                </div>
                <div>平均响应时长</div>
              </div>
              <div class="advantages flex_center flex_column">
                <div class="advantages_top">
                  <span class="num">90</span>
                  <span class="unit">%</span>
                </div>
                <div>平均20s接起率</div>
              </div>
              <div class="advantages flex_center flex_column">
                <div class="advantages_top">
                  <span class="num">30</span>
                  <span class="unit">万</span>
                </div>
                <div class="text">日均医疗服务次数</div>
              </div>
            </div>
            <div class="oat_details">
              <img class="quotes_logo" src="../../../static/img/openPlatform/quotes_logo.png" alt="" srcset="">
              <div class="oat_title">医疗实力</div>
              <div class="oat_content">
                莲藕医生自2019年品牌启动来，以平均每月以20%的稳定增长持续至今，<br>
                已开通了内科、外科、皮肤科、儿科、妇产科、中医科等16个常见科室，<br>
                涵盖1562个疾病诊断，可开具药品通用名7713个。<br>
                现已完成1亿次的医疗服务，现日均医疗服务达30万人次。
              </div>
            </div>
          </div>
          <div class="our_advantages_bottom flex_between box_shadow">
            <div class="oab_details">
              <img class="quotes_logo" src="../../../static/img/openPlatform/quotes_logo.png" alt="" srcset="">
              <div class="oab_title">技术实力</div>
              <div class="oab_content">
                莲藕科技专注医药数字化基础设施研发，截止2021年7月已获得医药类软著30项。针对互联网医疗重点建设“莲藕处方风控系统”、“莲藕合理用药系统”、“莲藕在线智能接诊助手”等，切实提高医生接诊效率3倍以上，累计为拦截风险处方超千万次。
              </div>
              <div class="oab_content" style="margin-top: 20px;">
                部署腾讯云、阿里云、华为云、电信云四个私有云中心，支持千万级并发，每日100G+的数据留存、每日数千万次的数据调用。
              </div>
            </div>
            <div class="flex_between technical_strength_box">
              <div class="technical_strength flex_start">
                <img class="technical_strength_img" src="../../../static/img/openPlatform/icon_API.png">
                <div class="technical_strength_details">
                  <div class="title">接入方式灵活</div>
                  <div class="content">支持H5、API，满足<br>合作方式的各种接入场景</div>
                </div>
              </div>
              <div class="technical_strength flex_start">
                <img class="technical_strength_img" src="../../../static/img/openPlatform/icon_camera.png">
                <div class="technical_strength_details">
                  <div class="title">质控体系完备</div>
                  <div class="content">面向全流程建立数据指标，<br>动态监控保证服务质量</div>
                </div>
              </div>
              <div class="technical_strength flex_start">
                <img class="technical_strength_img" src="../../../static/img/openPlatform/icon_tech.png">
                <div class="technical_strength_details">
                  <div class="title">技术保障全面</div>
                  <div class="content">提供技术支持服务，协助<br>快速开发、测试、上线</div>
                </div>
              </div>
              <div class="technical_strength flex_start">
                <img class="technical_strength_img" src="../../../static/img/openPlatform/icon_cust.png">
                <div class="technical_strength_details">
                  <div class="title">客服支持稳定</div>
                  <div class="content">以稳定高效的客户服务，<br>为您的接入体验保驾护航</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="access_process">
      <div class="access_process_btn" @click="$router.replace({path: 'applyAccess'})" />
    </div>
    <div class="cooperation">
      <div class="content_box">
        <div class="title">期待与您合作</div>
        <div class="subtitle">我们致力于为您提供数字化解决方案</div>
        <div class="box_shadow flex_between">
          <div class="contact_item flex_none">
            <div class="contact_title flex_center">
              <!-- <img class="contact_icon" src="../../../assets/cloud/留下联系方式@2x.png" alt="" srcset=""> -->
              <span class="contact_type">获取您的专属解决方案</span>
            </div>
            <div class="form_body flex_column_start">
              <input v-model="name" type="text" placeholder="请输入您的姓名">
              <input v-model="phone" type="text" maxlength="11" placeholder="请输入您的联系电话">
              <button v-loading="loading" type="button" @click="subimt">获取专业方案</button>
            </div>
          </div>
          <div class="code_details flex_between">
            <img class="code_logo" src="../../../static/img/openPlatform/icon_code.png">
            <div class="code_details_box flex_column flex_between">
              <div>
                <div class="code_details_title">莲藕科技</div>
                <div class="code_details_subtitle">医疗数字化基础设施及服务提供商</div>
              </div>
              <div class="code_details_bottom flex_start"><img class="icon_arrow" src="../../../static/img/openPlatform/icon_arrow.png"><span>扫码添加专属顾问</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" />
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
  data() {
    return {
      tabIndex: 0,
      aibTabIndex: 0,
      name: '',
      phone: '',
      loading: false,
      going: false,
      aegin: false
    }
  },
  methods: {
    subimt() {
      if (this.going) { if (!this.aegin) { this.aegin = true; ElMessage.error('请勿重复操作') } return }
      this.going = true
      setTimeout(() => {
        this.going = false
        this.aegin = false
      }, 3000)
      this.loading = true
      if (!this.name) { ElMessage.error('请输入姓名再提交'); this.loading = false; return }
      if (!this.phone) { ElMessage.error('请输入手机号再提交'); this.loading = false; return }
      const myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!myreg.test(this.phone)) {
        ElMessage.error('手机号格式不正确')
        this.loading = false
        return
      }
      this.$api.GetMessageToken({}).then(res => {
        this.$api.AddCooperationCommonMessage({
          'RemindContent': '获取您的专属解决方案',
          'UserName': this.name,
          'Phone': this.phone,
          'SubmitPage': window.location.href,
          'Token': res.data
        }).then(({ code, msg }) => {
          this.loading = false
          if (code) {
            ElMessage.success('提交成功!')
            this.name = ''
            this.phone = ''
          } else ElMessage.error(msg)
        }).catch(err => {
          this.loading = false
          ElMessage.error(err)
        })
      })
    }
  }
}
</script>

<style lang="scss">
body {
  font-size: 16px;
  letter-spacing: 1.92px;
  line-height: 1.25;
}
.fontSize20 {
  font-size: 20px;
}
.open_platform {
  text-align: left;
}
.content_box {
  width: 1200px;
  margin: 0 auto;
}
.box_shadow {
  background: #FFFFFF;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
.open_platform {
  .header {
    background-image: url('../../../static/img/openPlatform/banner.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    min-width: 1200px;
    height: 574px;
    .header_top {
      max-width: 1200px;
      height: 88px;
    }
    .open_platform_logo {
      height: 28px;
      cursor: pointer;
      >img {
        height: 100%;
      }
    }
    .header_nav {
      color: #D3C5FE;
      letter-spacing: 0;
      .action_nav {
        color: #ffffff;
        font-weight: bold;
      }
      >div {
        cursor: pointer;
        margin-right: 70px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
  .open_platform_tab {
    padding: 70px 0;
    .content_box {
      .tabs {
        .tab {
          cursor: pointer;
          align-items: flex-start;
          height: 150px;
          box-sizing: border-box;
          padding: 34px 41px;
          margin-bottom: 40px;
          background: #ffffff;
          box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.04);
          border-radius: 10px;
          flex: 1;
          &:nth-last-child(1) {
            margin-left: 30px;
          }
          .tab_img {
            width: 80px;
            height: 80px;
          }
          .tab_details {
            margin-left: 20px;
            text-align: left;
            .title {
              margin-bottom: 10px;
              font-size: 20px;
              font-weight: 600;
              color: #333333;
            }
            .content {
              color: #A8ABB3;
            }
          }
        }
        .tab_action {
          padding: 34px 41px 44px;
          height: 159px;
          margin-bottom: 31px;
          background: url('../../../static/img/openPlatform/tab_bg.png') no-repeat;
          background-size: 100% 100%;
          .tab_details {
            .title,.content {
              color: #ffffff;
            }
          }
        }
      }
      .tabs_content {
        .tabs_content_top {
          position: relative;
          .tabs_content_details {
            padding: 50px 30px;
            .title {
              font-size: 24px;
              color: #333333;
              margin-bottom: 20px;
              font-weight: 600;
              margin-top: 50px;
            }
            .service_profile {
              .title {
                margin-top: 0;
              }
              .content > div {
                padding-left: 26px;
                line-height: 26px;
                width: 677px;
                margin-bottom: 18px;
                position: relative;
                letter-spacing: 1px;
                &:nth-last-child(1) {
                  margin-bottom: 0;
                }
                &::before {
                  content: ' ';
                  position: absolute;
                  width: 14px;
                  height: 14px;
                  left: 0;
                  top: 6px;
                  background-image: url('../../../static/img/openPlatform/icon_pun.png');
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
            .for_enterprise {
              .content {
                .margin_right_82 {
                  margin-right: 82px;
                }
                .flex_start:nth-child(1) {
                  margin-bottom: 12px;
                }
                .flex_center {
                  > img {
                    width: 16px;
                    margin-right: 10px;
                  }
                }
              }
            }
            .support_access_system {
              .content {
                .flex_column {
                  margin-left: 60px;
                  &:nth-child(1) {
                    margin-left: 0;
                  }
                  >img {
                    width: 46px;
                    margin-bottom: 8px;
                  }
                  >span {
                    line-height: 26px;
                  }
                }
              }
            }
          }
          .sample_img {
            position: absolute;
            right: 0;
            top: 70px;
            >img {
              height: 548px;
            }
          }
        }
        .application_interaction_example {
          margin-top: 70px;
          text-align: center;
          .title {
            margin-bottom: 20px;
            font-size: 30px;
            font-weight: 600;
            letter-spacing: 3px;
          }
          .aie_tabs {
            margin-bottom: 30px;
            .aie_tab {
              cursor: pointer;
              color: #A8ABB3;
              padding: 10px 32px;
              font-size: 18px;
              &:nth-child(1) {
                margin-right: 30px;
              }
            }
            .aie_tab_action {
              color: #ffffff;
              background: linear-gradient(279deg, #7458FD 0%, #4C29FD 100%);
            }
          }
          .aie_img {
            >img {
              width: 1200px;
            }
          }
        }
      }
    }
  }
  .cooperative_enterprise {
    background-image: url('../../../static/img/openPlatform/cooperative_enterprise.png');
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 667px;
    min-width: 1200px;
  }
  .our_advantages {
    padding: 100px 0;
    .content_box {
      .title {
        margin-bottom: 40px;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 3px;
      }
      .content {
        .our_advantages_top {
          width: 1200px;
          height: 292px;
          box-sizing: border-box;
          padding: 60px 47px 60px 100px;
          background-image: url('../../../static/img/openPlatform/medical_strength_bg.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          align-items: flex-start;
          .advantages {
            margin-left: 80px;
            font-size: 12px;
            &:nth-child(1) {
              margin-left: 0;
            }
            .advantages_top {
              margin-bottom: 10px;
              color: #6E67F0;
              .num {
                font-size: 50px;
                font-weight: bold;
              }
              .unit {
                font-size: 16.5px;
              }
            }
          }
          .oat_details {
            position: relative;
            margin-top: 20px;
            .oat_title {
              position: relative;
              color: #333333;
              font-size: 22px;
              font-weight: 600;
              line-height: 30px;
              letter-spacing: 2px;
              margin-bottom: 18px;
            }
            .oat_content {
              position: relative;
              color: #606266;
              font-size: 14px;
              line-height: 28px;
              letter-spacing: 1px;
            }
            .quotes_logo {
              position: absolute;
              bottom: 65px;
              right: -11px;
              width: 50px;
            }
          }
        }
        .our_advantages_bottom {
          padding: 50px 30px;
          padding-left: 40px;
          .oab_details {
            width: 564px;
            flex: none;
            margin-right: 60px;
            position: relative;
            .oab_title {
              position: relative;
              color: #333333;
              font-size: 22px;
              font-weight: 600;
              line-height: 30px;
              letter-spacing: 2px;
              margin-bottom: 18px;
            }
            .oab_content {
              position: relative;
              color: #606266;
              font-size: 14px;
              line-height: 28px;
              letter-spacing: 1px;
            }
            .quotes_logo {
              position: absolute;
              top: 26px;
              right: -20px;
              width: 50px;
            }
          }
          .technical_strength_box {
            flex-wrap: wrap;
            .technical_strength {
              width: 243px;
              height: 110px;
              margin-bottom: 20px;
              margin-right: 20px;
              padding-left: 20px;
              box-sizing: border-box;
              background: #F5F7FA;
              border-radius: 4px;
              &:nth-last-child(1),&:nth-last-child(2) {
                margin-bottom: 0;
              }
              &:nth-last-child(1),&:nth-last-child(3) {
                margin-right: 0;
              }
              .technical_strength_img {
                width: 40px;
                margin-right: 20px;
              }
              .technical_strength_details {
                .title {
                  font-size: 16px;
                  text-align: left;
                  margin-bottom: 6px;
                }
                .content {
                  font-size: 12px;
                  text-align: left;
                  line-height: 17px;
                  color: #8B8A99;
                  letter-spacing: 1px;
                }
              }
            }
          }

        }
      }
    }
  }
  .access_process {
    background-image: url('../../../static/img/openPlatform/access_process.png');
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 570px;
    min-width: 1200px;
    position: relative;
    .access_process_btn {
      position: absolute;
      width: 317px;
      height: 160px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
  .cooperation {
    padding: 100px 0;
    .content_box {
      .title {
        margin-bottom: 14px;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 3px;
      }
      .subtitle {
        margin-bottom: 40px;
        font-size: 22px;
        text-align: center;
      }
      .code_details {
        padding: 37px 60px 37px 70px;
        .code_logo {
          width: 216px;
          margin-right: 30px;
        }
        .code_details_box {
          height: 216px;
          .code_details_title {
            font-size: 28px;
          }
          .code_details_subtitle {
            font-size: 20px;
            color: #A8ABB3;
            margin-top: 14px;
          }
          .code_details_bottom {
            width: 100%;
          }
          .icon_arrow {
            width: 70px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .footer {
    background-image: url('../../../static/img/openPlatform/footer_bg.png');
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 575px;
    min-width: 1200px;
  }
}
.contact_item {
  padding: 25px;
  border-right: 1px solid #EDEDF0;
  .contact_title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    margin-bottom: 30px;
    text-align: center;
  }
  .phone_item {
    align-items: center;
    padding-bottom: 30px;
    .phone_item_name {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .phone_item_phone {
      margin-top: 4px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #330000;
      line-height: 28px;
    }
    >img {
      display: block;
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
  .qrcode_body {
    .qrcode_item {
      margin-right: 50px;
      align-items: center;
      >img {
        width: 140px;
        display: block;
        margin-bottom: 6px;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
  .form_body {
    >input {
      width: 100%;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      background: #F5F7FA;
      border-radius: 4px;
      margin-top: 14px;
      border: none;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    >button {
      width: 100%;
      margin-top: 26px;
      font-size: 16px;
      color: #ffffff;
      height: 40px;
      line-height: 40px;
      background: linear-gradient(239deg, #7458FD 0%, #4C29FD 100%);
      border-radius: 4px;
      border: none;
      cursor: pointer;
    }
  }
  &:nth-last-child(1) {
    border-right: none;
  }
}
</style>
